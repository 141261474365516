function minutesec(milli) {
    const s = Math.floor(milli / 1000)
    let sec = s % 60
    const min = (s - sec) / 60
    if (sec < 10) sec = "0" + sec
    return min+":"+ sec
}
export function SongInfo(props) {
    return (
        <div> <pre style={{textAlign:"left"}}>
            <div>title   {props.info.item.name}</div>
            <div>album   {props.info.item.album.name}</div>
            <div>artists {props.info.item.artists.map(a=>a.name).join(", ").substring(0,30)}</div>
            <div>release {props.info.item.album.release_date}</div>
            <div>{minutesec(props.info.progress_ms)}/{minutesec(props.info.item.duration_ms)}</div>
        </pre>
        </div>
    )
}

