
import { signal } from "@preact/signals-react";
import { SongText } from './songtext';
import { SongInfo } from './songinfo';
const data2={
	"id": "10DSYghyBjAYJa8iCvdRDv",
	"info": {
	  "timestamp": 1685113010446,
	  "progress_ms": 12104,
	  "item": {
		"album": {
		  "album_type": "single",
		  "href": "https://api.spotify.com/v1/albums/0IJBIXei1Tk5B2LAJG88uM",
		  "id": "0IJBIXei1Tk5B2LAJG88uM",
		  "images": [ { "url": "https://i.scdn.co/image/ab67616d0000b273caad249a36b9be557e59e85e", } ],
		  "name": "SKY",
		  "release_date": "2018-04-05",
		},
		"artists": [ { "name": "Stamp", }, { "name": "Violette Wautier", } ],
		"duration_ms": 204999,
		"id": "10DSYghyBjAYJa8iCvdRDv",
		"name": "ฝันหวานอายจูบ",
		"popularity": 46,
	  }
	},
	"orig": {
	  "lyrics": {
		"syncType": "LINE_SYNCED",
		"lines": [
		  { "startTimeMs": "9120", "words": "ฉันฝันถึงเธอคนที่อยู่ไกลแสนไกล", "syllables": [], "endTimeMs": "0" },
		  { "startTimeMs": "13740", "words": "ช่างหวานละมุน อบอุ่นข้างในหัวใจ", "syllables": [], "endTimeMs": "0" },
		  { "startTimeMs": "39120", "words": "ฉันฝันถึงเธอคนที่อยู่ไกลแสนไกล", "syllables": [], "endTimeMs": "0" },
		  { "startTimeMs": "83740", "words": "ช่างหวานละมุน อบอุ่นข้างในหัวใจ", "syllables": [], "endTimeMs": "0" }
		],
		"language": "th",
	  },
	  "colors": {
		"background": -16745781,
		"text": -16777216,
		"highlightText": -1
	  },
	  "hasVocalRemoval": false
	},
	"translation": {
	  "lyrics": {
		"syncType": "LINE_SYNCED",
		"lines": [
		  { "startTimeMs": "9120", "words": "I dream of her far", "syllables": [], "endTimeMs": "0" },
		  { "startTimeMs": "13740", "words": "Warm in heart", "syllables": [], "endTimeMs": "0" },
		  { "startTimeMs": "39120", "words": "I dream of her far", "syllables": [], "endTimeMs": "0" },
		  { "startTimeMs": "83740", "words": "Warm in heart", "syllables": [], "endTimeMs": "0" },
		],
		"language": "th",
	  },
	  "colors": {
		"background": -16745781,
		"text": -16777216,
		"highlightText": -1
	  },
	  "hasVocalRemoval": false,
	  "detectedLanguage": {
		"confidence": 100,
		"language": "th"
	  }
	}
}
  
const data=signal(data2)
setInterval(() => {
	if (data.value.info) {
		const cp = structuredClone(data.value)
		const now=new Date()*1
		const startTime = cp.info.timestamp - cp.info.progress_ms
		const progress_ms=now-startTime
		cp.info.progress_ms =progress_ms
		cp.info.timestamp=now
		data.value = cp
//		console.log(data.value.info.progress_ms)
	}
}, 100);

fetch("/lyrics").then(res => res.json()).then(o => {
	data.value = o
	if (o.info) {
		console.log(new Date())
		console.log(new Date(o.info.timestamp))
		console.log(o.info.timestamp-new Date())
		console.log(o.info.progress_ms)
	}
})


export function SongInternal(props) {
    return (
      <>
        <SongInfo info={data.value.info}></SongInfo>
        <div style={{ width:"100%",display: "flex" }}>
          <SongText info={data.value.info} text={data.value.orig}></SongText>
          <SongText info={data.value.info} text={data.value.translation}></SongText>
        </div>
      </>
    )
}

export function Song(props) {
    return (
        <> {data.value.info ? <SongInternal></SongInternal> : <div>{JSON.stringify(data.value)}</div>}
        </>
    )
}

