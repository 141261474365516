import './App.css';
import { Song } from './song.js';

function App() {
  return (
    <div className="App">
      <Song style={{width:"100%"}}></Song>
    </div>
  );
}

export default App;
